import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import customTheme from './@chakra-ui/gatsby-plugin/theme';
import GlobalStyles from './GlobalStyles';
import Amplitude from './Amplitude';
import { config } from './utils/wagmi';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
// @ts-ignore
import style from '@rainbow-me/rainbowkit/styles.css';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          appInfo={{
            appName: 'ZOO DAO',
          }}
        >
          <ChakraProvider theme={customTheme}>
            <GlobalStyles />
            <Amplitude />
            <div style={style}>{element}</div>
          </ChakraProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
