import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';
import { createConfig } from 'wagmi';
import { storyNetwork } from '../constants/chain';
import generateHttpEndpoint, { CHAINS } from './generateHtpEndpoint';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, coinbaseWallet],
    },
  ],
  {
    projectId: '9e1f420e9d8b780b7231559606c7b47b',
    appName: 'ZOO DAO',
  }
);

export const config = createConfig({
  chains: CHAINS,
  multiInjectedProviderDiscovery: false,
  connectors,
  // @ts-expect-error Skip the type check for chain id
  transports: {
    [storyNetwork.id]: generateHttpEndpoint(storyNetwork.id),
  },
});

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}
