const colors = {
  'gray.100': '#111111',
  'gray.10': '#EAEAEA',
  'gray.30': '#B0B0B0',
  'gray.40': '#717171',
  'gray.0': '#FFFFFF',
  'brown.1': '#5F2408',
  'brown.2': '#463122',
  yellow: '#FFFDE1',
  'pink.1': '#D09CCF',
  'pink.2': '#874086',
  'green.1': '#53E753',
  'green.2': '#17B517',
  'blue.1': '#A5DDFE',
  'blue.2': '#16ABEF',
};

const semanticTokens = {
  colors: {
    primary: 'brown',
    bgColor: 'blue.1',
    headerColor: 'blue.2',
    footerColor: 'brown.2',
    borderColor: 'gray.100',
  },
};

export { colors, semanticTokens };
