const ctaPrimary = {
  py: '9px',
  px: '37px',
  height: 'auto',
  fontSize: '32px',
  fontWeight: 700,
  border: '5px solid',
  bg: 'green.1',
  position: 'relative',
  overflow: 'hidden',

  _hover: {
    _before: {
      transform: 'rotate(-41deg)',
    },
  },

  _before: {
    content: '""',
    display: 'block',
    width: '150px',
    height: '27px',
    position: 'absolute',
    bottom: '0',
    right: '-17px',
    bg: 'gray.0',
    opacity: '0.6',
    transition: '0.6s transform',
    transform: 'translate(-300%, -100%) rotate(-41deg)',
  },

  _after: {
    content: 'attr(data-after-content)',
    display: 'block',
    width: '100%',
    height: '100%',
    py: '9px',
    px: '37px',
    boxSizing: 'border-box',
    position: 'absolute',
    top: '0',
    left: '0',
    borderRadius: '0 3px 8px 3px',
    boxShadow: '-4px -5px 0px 0px #17B517 inset, 2px 2px 0px 0px #000',
  },
};

const primary = {
  height: 'auto',
  fontWeight: 400,
  border: '2px solid',
  borderLeftColor: 'gray.100',
  borderRightColor: 'gray.100',
  borderTopColor: 'gray.100',
  borderBottomColor: 'gray.100',
  bg: 'green.1',
  boxShadow: '0px -4px 0px 0px #17B517 inset, 2px 2px 0px 0px #000',

  _hover: {
    bg: { base: 'green.1', lg: 'green.2' },
  },

  _disabled: {
    opacity: 1,
    boxShadow: '0px -4px 0px 0px #17B517 inset, 2px 2px 0px 0px #000',
  },
};

export const Button = {
  baseStyle: {
    lineHeight: 'normal',
    borderColor: 'gray.100',
    borderRadius: '8px',
    _hover: { textDecoration: 'none' },
  },
  variants: {
    ctaPrimary,
    ctaSecondary: {
      ...ctaPrimary,
      bg: 'pink.1',
      color: 'gray.0',
      borderLeftColor: 'gray.100',
      borderRightColor: 'gray.100',
      borderTopColor: 'gray.100',
      borderBottomColor: 'gray.100',

      _after: {
        ...ctaPrimary._after,
        boxShadow: '-4px -5px 0px 0px #874086 inset, 2px 2px 0px 0px #000',
      },
    },
    primary,
    secondary: {
      ...primary,
      bg: 'pink.1',
      boxShadow: '0px -4px 0px 0px #874086 inset, 2px 2px 0px 0px #000',

      _hover: {
        _disabled: {
          bg: 'pink.1',
        },
      },

      _disabled: {
        opacity: 1,
        boxShadow: '0px -4px 0px 0px #874086 inset, 2px 2px 0px 0px #000',
      },
    },
  },
  defaultProps: {
    variant: 'ctaPrimary',
    size: 'normal',
  },
  sizes: {
    sm: {
      p: '8px 16px',
      fontSize: '14px',
    },
    normal: {
      p: '8px 24px',
      fontSize: '24px',
    },
    cta: {
      p: '14px 42px',
      fontSize: '32px',
    },
  },
};
