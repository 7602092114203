import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

const Amplitude = () => {
  useEffect(() => {
    if (process.env.GATSBY_AMPLITUDE_API_KEY) {
      amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY);
    }
  }, []);

  return null;
};

export default Amplitude;
