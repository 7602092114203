export const Tooltip = {
  baseStyle: {
    padding: '16px',
    fontFamily: 'Kreon',
    fontSize: '14px',
    lineHeight: 'normal',
    color: 'gray.100',
    bg: 'gray.0',
    border: '2px solid',
    borderColor: 'gray.100',
    boxShadow: '8px 8px 0px 0px #111',
  },
};
