import { extendTheme } from '@chakra-ui/react';
import { components } from './components';
import textStyles from './textStyles';
import { colors, semanticTokens } from './colors';

const theme = {
  fonts: {
    heading: `'Handjet', sans-serif`,
    body: `'Handjet', sans-serif`,
    Kreon: `"Kreon", sans-serif`,
  },
  styles: {
    global: {
      'html, body': { margin: 0, bg: 'bgColor', scrollBehavior: 'smooth' },
      body: { margin: 0, color: 'gray.100' },
      p: { fontSize: '16px', lineHeight: 'normal' },
    },
  },
  breakpoints: {
    base: '0px',
    md: '834px',
    lg: '1024px',
    xl: '1440',
    '2xl': '2560px',
  },
  colors,
  semanticTokens,
  textStyles,
  components,
};

export default extendTheme(theme);
