import { defineChain } from 'viem';

const storyAeneidTestnet = defineChain({
  id: 1315,
  name: 'Story Aeneid Testnet',
  nativeCurrency: {
    name: 'IP',
    symbol: 'IP',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['https://aeneid.storyrpc.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Story Explorer',
      url: 'https://aeneid.storyscan.xyz',
    },
  },
});

const storyMainnet = defineChain({
  id: 1514,
  name: 'Story Mainnet',
  nativeCurrency: {
    name: 'IP',
    symbol: 'IP',
    decimals: 18,
  },
  rpcUrls: {
    default: { http: ['https://mainnet.storyrpc.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Story Explorer',
      url: 'https://www.storyscan.xyz/',
    },
  },
});

export const storyNetwork =
  process.env.GATSBY_APP_ENV === 'production'
    ? storyMainnet
    : storyAeneidTestnet;
