import React from 'react';
import { Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Handjet';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Handjet/Handjet.ttf') format('ttf'), url('/fonts/Handjet/Handjet.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @keyframes scale-down {
        to {
          scale: 0.1;
        }
      }
      @keyframes marqueeFirstElement {
        0% {
          transform: translateX(0);
        }
        49.8% {
          transform: translateX(-100%);
          opacity: 1;
        }
        49.9% {
          transform: translateX(-100%);
          opacity: 0;
        }
        50.1% {
          transform: translateX(100%);
          opacity: 0;
        }
        50.2% {
          transform: translateX(100%);
          opacity: 1;
        }
        100% {
          transform: translateX(0);
        }
      }
      @keyframes marqueeSecondElement {
        0% {
          transform: translateX(0);
        }
        99.6% {
          transform: translateX(-200%);
          opacity: 1;
        }
        99.7% {
          transform: translateX(-200%);
          opacity: 0;
        }
        99.9% {
          transform: translateX(0);
          opacity: 0;
        }
        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }
      `}
  />
);

export default GlobalStyles;
