const textStyles = {
  'subtitle-lg': {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: 'normal',
  },
  'subtitle-sm': {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  h1: {
    fontWeight: '700',
    fontSize: '64px',
    lineHeight: 'normal',
  },
  body: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: 'normal',
  },
  caption: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  button: {
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: 'normal',
  },
};

export default textStyles;
