import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// It's a custom style for Earn page
const custom = definePartsStyle(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
      alignItems: 'center',
      gap: { base: '12px', lg: '40px' },
    },
    tab: {
      gap: '10px',
      width: { base: 'calc(100% / 3)', lg: '200px' },
      height: '65px',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: 'normal',
      bg: 'pink.1',
      border: '3px solid',
      borderColor: 'gray.0',
      borderRadius: '8px',

      img: { display: 'none' },

      _hover: { img: { display: { base: 'none', lg: 'block' } } },

      _selected: {
        color: 'gray.0',
        bg: 'pink.2',

        img: { display: { base: 'none', lg: 'block' } },
      },

      _disabled: {
        opacity: 1,
        color: '#717171',
        bg: 'gray.30',

        _hover: { img: { display: { base: 'none', lg: 'none' } } },
      },
    },
    tablist: {
      flex: '0 0 auto',
      gap: { base: '8px', lg: '14px' },
    },
    tabpanel: { p: 0, minHeight: '268px' },
  };
});

const variants = {
  custom,
};

export const Tabs = defineMultiStyleConfig({
  variants,
});
