import { Heading } from './Heading';
import { Button } from './Button';
import { Tabs } from './Tabs';
import { Tooltip } from './Tooltip';

export const components = {
  Heading,
  Button,
  Tabs,
  Tooltip,
};
