export const Heading = {
  sizes: {
    'heading.1': {
      fontSize: '64px',
      fontWeight: '700',
      lineHeight: 'normal',
      color: 'gray.0',
      textAlign: 'center',
      WebkitTextStrokeWidth: '15px',
      WebkitTextStrokeColor: '#5F2408',
      paintOrder: 'stroke fill',
    },
    'heading.2': {
      fontSize: '40px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    'heading.3': {
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
  },
};
